
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonImg,
  IonButtons,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../../components/Empty.vue";
import { Analytics } from "../../../../common/analytics";
/**
 * services
 */
import services from "../../../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    Empty,
    IonSpinner,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonImg,
    IonButtons,
  },
  props: {
    // userId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props) {
    const router = useRouter();
    const screenName = `PAGE-USER_${props.id}_GIFT`;
    const analytics = new Analytics();
    const state = reactive({
      mission: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      contentLoading: true,
      isScrollDisabled: false,
    });

    const fetch = async (event: any = null, loadMore = false) => {
      const params = {
        size: state.mission.size,
        page: state.mission.page,
      };
      await services.missionUserRewardFindone(props.id, params).then(
        (response) => {
          const { data } = response;
          if (event) {
            if (event.type == "ion-refresh") {
              state.mission.items = [];
            }
          }

          data.items?.some((item: any) => {
            if (item.complete == 0) {
              if (item.content.reward[0].pictureGray) {
                if (item.content.reward[0].pictureGray.name) {
                  item.content.reward[0].picture =
                    item.content.reward[0].pictureGray;
                }
              }
            }
            if (item.completeStampCount < item.acquisitionStampCount) {
              item.acquisitionStampCount = item.completeStampCount;
            }
            state.mission.items.push(item);
          });

          state.mission.totalItems = data.totalItems;
          state.mission.totalPages = data.totalPages;
          state.mission.loading = false;
          state.mission.page++;
          if (event) {
            if (data.items?.length == 0 && loadMore) {
              state.isScrollDisabled = true;
            }
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          console.log(message);
          state.mission.loading = false;
          if (event) {
            if (event.type == "ion-refresh") {
              state.mission.items = [];
            }
          }
          state.isScrollDisabled = true;
        }
      );
      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const onApplicationLink = (id: number, level: number) => {
      router.push({
        name: "MissionIdApplication",
        params: { id: id, level: level },
      });
    };

    const onMissionLink = (id: number, level: number) => {
      router.push({
        name: "MissionId",
        params: { id: id, level: level },
      });
    };

    const onClose = () => {
      window.history?.length > 1 ? router.go(-1) : router.push("/");
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.mission.page = 1;
      state.mission.loading = true;
      state.isScrollDisabled = false;
      fetch(event);
    };

    // onMounted
    onMounted(() => {
      state.mission.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return {
      state,
      onApplicationLink,
      onMissionLink,
      onClose,
      onLoadData,
      onRefresh,
    };
  },
});
